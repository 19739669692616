import React from "react";

const ContactPage = () => {
    return ( 
        <>
            <section className="flex mt-9 pt-5 mx-5 justify-center h-screen items-center">
            <div className="my-20 mx-5 flex flex-col ">
                <span>telehpone number</span>
                <a href="tel:581-999-4568" className="h1 pb-5 mb-5 text-5xl">581.999.4568</a>
                <span>email address</span>
                <a href="mailto:will.gauvin@gmail.com" className="h1 pb-5 mb-5 text-5xl">will.gauvin@gmail.com</a>
            </div>
        </section>
        
        
        
        </>
     );
}
 
export default ContactPage;