import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { ReactComponent as WgLogo} from '../../assets/images/Wg_logo.svg'; 


const NavBar = () => {

    const [navbarOpen, setNavbarOpen] = useState(false);

    // const handleToggle = () => {
    //     setNavbarOpen(prev => !prev)
    //   }


    return ( 
        <header>
            <div className="mx-5">
                <nav className="flex flex-wrap smm:items-center justify-items-start smm:justify-between flex-col align-items-center smm:flex-row">

                    <div className="flex justify-between">
                        <NavLink to="/" exact activeClassName="underline" onClick={() => setNavbarOpen(false)}>
                            <div className="py-3 mr-4 text-black hover:text-blue-500 flex items-center">
                                <WgLogo className="w-8 mr-2"/>
                                <p href="#" className="h1 text-3xl uppercase">William Gauvin</p>
                            </div>
                        </NavLink>

                        <div className="flex smm:hidden">
                            <button onClick={() => setNavbarOpen(!navbarOpen)} type="button" id="hamburgerButton" className="p-5 h4 text-xl " aria-label="toggle menu">
                            {navbarOpen ? "X" : "Menu"}
                                
                            </button>
                        </div>

                    </div>
                    
                    
                    <div className="items-center smm:flex ">
                        <div id="menu" className={`menuNav ${navbarOpen ? "" : "hidden"} flex smm:flex items-center flex-col mt-20 smm:my-0 my-auto smm:flex-row h-screen smm:h-auto`}>
                                
                                {/* <NavLink to="/" exact
                                activeClassName="underline"
                                className="md:hidden py-3 lg:px-5 px-3  rounded uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >Home</NavLink> */}


                                <NavLink to="/uiux"
                                activeClassName="underline"
                                className="py-3 lg:px-5 px-3  rounded uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >Ui/Ux</NavLink>
                                
                                <NavLink to="/webdev"
                                activeClassName="underline"
                                className="py-3 lg:px-5 px-3 rounded  uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >WebDev</NavLink>

                                <NavLink to="/branding"
                                activeClassName="underline"
                                className="py-3 lg:px-5 px-3 rounded  uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >Branding</NavLink>

                                <NavLink to="/about"
                                activeClassName="underline"
                                className="py-3 lg:px-5 px-3 rounded  uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >About</NavLink>

                                <NavLink to="/contact"
                                activeClassName="underline"
                                className="py-3 lg:px-5 px-3 rounded  uppercase hover:text-blue-500 navLink"
                                onClick={() => setNavbarOpen(false)}
                                >Contact</NavLink>
                                
                                <div className="inline-flex py-3 lg:px-5 px-6 my-4">
                                    <SocialIcon url="https://www.linkedin.com/in/william-gauvin-626357105" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{ height: 32, width: 32 }}/>
                                    <SocialIcon url="https://dribbble.com/WGOVE" className="mr-4" target="_blank" fgColor="#fff" bgColor="#000" style={{ height: 32, width: 32 }}/>
                                </div>
                    
                        </div>
                    </div>

                </nav>
            </div>
        </header>
     );
}
 
export default NavBar;


