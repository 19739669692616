import React, { useEffect, useState  } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

export default function SinglePost() {
    const [singlePost, setSinglePost] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
        .fetch(`*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            projectName,
            type,
            years,
            place,
            description,
            body,
            "name": author->name,
            "authorImage": author->image,
         }`)
        .then((data) => setSinglePost(data[0]))
        .catch(console.error);
    }, [slug]);

    if (!singlePost) return <div className="h-screen my-auto mx-auto h2">...</div>;

    return (
        <div className="bgtest min-h-screen p-2 md:p-12 mt-20">
            <article className="bg-white p-5 md:container md:border-black md:border-4 mx-auto lg:px-48">
                <section className="md:pt-20">
                    <span className="h5 uppercase tracking-wider text-xs">{singlePost.type}</span>
                    <h1 className="h2 text-3xl lg:text-6xl mb-4 md:my-8">{singlePost.title}</h1>
                    <div className="flex justify-between">
                        <p className="">Date: {singlePost.years}</p>
                        <h2 className="pbold">{singlePost.projectName}</h2>
                    </div>
                    <div className="border-black border-solid border-t-4 border-b-4 flex flex-col items-center">
                    <img src={singlePost.mainImage.asset.url} alt={singlePost.projectName} className="h-auto py-4"/>
                    </div>


                
                </section>
                <div className=" py-12 lg:py-20 prose lg:prose-xl max-w-full">
                    
                    <BlockContent 
                    blocks={singlePost.body} 
                    projectId="pfhsy09s" 
                    dataset="production" 
                    />
                </div>
                <Link to="/about">
                <div className="flex justify-center items-center text-gray-800 hover:text-blue-500">
                            
                    <img src={urlFor(singlePost.authorImage).url()} 
                        alt={singlePost.name} 
                        className="w-12 h-12 rounded-full border-black border-2 bg-white"/>
                    <p className="flex item-center pl-2 text-3xl h1 uppercase">{singlePost.name}</p>
                             
                </div>
                </Link>
            </article>
        </div>
    )
}