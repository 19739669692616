import sanityClient from "../client";
import React, { useState, useEffect } from "react";

export default function Experience(props) {
    const category = props.category;
    const [experienceData, setPost] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "experience"]{
                categorie,
                type,
                logo{
                    asset->{
                        _id,
                        url
                    },
                },
                company,
                years,
                title,
                description,
                tags,
            }`)
            .then((data) => setPost(data.filter((experience) => {
                return experience.categorie === category;
            })))
            .catch(console.error);
        }, [category]);

        return (
            <div className="">
                <section className="mx-auto">
                    <div className="grid  md:grid-cols-3 lg:gird-cols-4 gap-4">
                        {experienceData && experienceData.map((experience, index) => (
                            <div className="bg-white border-solid border-black border-4 p-8 h-100">
                                <div className="flex pb-10 justify-between">
                                    <div className="flex flex-col">
                                        <span className="text-xs uppercase pb-3 tracking-wide">{experience.type}</span>
                                        <span className="">{experience.company}</span>
                                        <span className="text-xs uppercase">{experience.years}</span>
                                    </div>
                                    <img src={experience.logo.asset.url} alt={experience.logo.alt} className="object-contain w-12"/>      
                                </div>
                                
                                <h3 className="text-3xl font-bold pb-10">{experience.title}</h3>
                                <p className="pb-10 text-sm">{experience.description}</p>
                                <span className="text-xs uppercase tracking-wide">{experience.tags}</span>
                                

                            </div>

                            
            ))}
                    </div>
                </section>
            </div>
    )
}