import { BrowserRouter, Route, Switch } from "react-router-dom";


import HomePage from "./components/pages/HomePage";
import UiUxPage from "./components/pages/UiUxPage";
import WebDevPage from "./components/pages/WebDevPage";
import BrandingPage from "./components/pages/BrandingPage";
import AboutPage from "./components/pages/AboutPage";
import ContactPage from "./components/pages/ContactPage";
import SinglePost from "./components/SinglePost";
import Post from "./components/Post";

import ScrollToTop from "./components/pages/ScrollToTop";
import NavBar from "./components/nav/NavBar";
import Footer from "./components/nav/Footer";


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop>
    <NavBar />
      <Switch>
        <Route component={HomePage} path='/' exact />
        <Route component={UiUxPage} path='/UiUx' />
        <Route component={WebDevPage} path='/webdev' />
        <Route component={BrandingPage} path='/branding' />
        <Route component={AboutPage} path='/About' />
        <Route component={ContactPage} path='/Contact' />
        <Route component={SinglePost} path='/post/:slug' />
        <Route component={Post} path='/post' />

      </Switch>
      <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
