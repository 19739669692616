import React from "react";
import Post from "../Post";
import Page from "../Page";
import uiuxpageimg from '../../assets/images/uiuxpageimg.png';
import { Link } from "react-router-dom";




export default function UiUxPage () {
    return (
        <>
            <section className="flex flex-col md:flex-row pt-8 border-b-4 md:border-b-8  border-black md:mx-5">
                <div className="relative justify-center my-20 mx-5 ">
                    <h1 className="h1_hero  pb-5 mb-5 ">UI/UX <br></br> DESIGN</h1>
                </div>

                <div className="flex my-auto mx-auto">
                
                <img src={uiuxpageimg} alt="hello" className="bg-cover"/>
    
                </div>

            </section>

            <Page>
                <Post category={"uiux"}/>
            </Page>


        <section className="px-5 py-16 grid  md:grid-cols-2 gap-4 lg:gap-8 md:container mx-auto">

                <Link to="/webdev">
                    <div className="flex flex-col border-black border-4 hover:shadow-xl">
                        <h2 className="h1 gradientPink p-12 fontXL text-center">WEB<br></br>DEV</h2>

                        <button className="py-3 bg-black text-white text-center hover:shadow-lg hover:bg-blue-500 transition ease-out duration-200">
                            View projects
                        </button>
                    </div>
                </Link>

                <Link to="/branding">
                    <div className="flex flex-col border-black border-4 hover:shadow-xl">
                        <h2 className="h1 gradientGreen p-12 fontXL text-center">LOGOS &<br></br>BRANDING</h2>

                        <button className="py-3 bg-black text-white text-center hover:shadow-lg hover:bg-blue-500 transition ease-out duration-200">
                            View projects
                        </button>
                    </div>
                </Link>


            </section>
        
        </>

    )
}
