import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";

export default function Post(props) {
    const category = props.category;
    // const highlightPostHome = props.highlightPostHome;
    

    const [postData, setPost] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "post"]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
                projectName,
                type,
                years,
                place,
                description,
                categories,
                highlightPostHome,
                tags,
                datepublished,
            }
            `)
            .then((data) => setPost(data.filter((post) => {
                return post.categories === category;
            })))
            .catch(console.error);
    }, [category]
    
    
    );

    return (

            <section className="md:px-5 lg:container md:mx-auto py-20">
                <h2 className="h1 text-6xl mb-5 mt-4">Projets</h2>
                <div className="grid  smm:grids-cols-1 lg:grid-cols-2 2xl:gird-cols-3 gap-4 lg:gap-8">
                    {postData && postData.map((post, index) => (
                    <article className="bg-white border-solid md:border-4 border-t-4 border-b-4 border-black grid md:grid-cols-2 md:grid-rows-3 hover:border-2  gap-4 hover:shadow-xl transition ease-out">


                            <div className="flex flex-col py-4  pl-4 pt-4 md:pl-8 md:justify-end ">
                                <span className="text-xs uppercase pb-4" key={post.type.current}>{post.type}</span>
                                <span className="h4 text-lg" key={post.projectName.current}>{post.projectName}</span>
                                <span className="text-xs" key={post.place.current}><strong key={post.years.current}>{post.years}</strong>{" - "}{post.place}</span>
                            </div>

                            <Link to={"/post/" + post.slug.current} key={post.slug.current} className="md:row-span-3 flex flex-col my-auto items-center md:pr-4"> 
                                <div>
                                    <img src={post.mainImage.asset.url} alt={post.mainImage.alt} className="object-contain"/>
                                </div>
                            </Link>

                            <div className="px-4 pb-4  md:px-8 md:row-span-2 flex justify-between flex-col " key={index} >
                                <div>
                                    <h3 className="text-gray-900 text-2xl md:text-3xl pb-2 h2" key={post.title.current}>{post.title}</h3>
                                    <p className="pb-5 text-s text-gray-800" key={post.description.current}>{post.description}</p>
                                </div>

                                <a className="pt-3 mb-4 w-8/12 px-6 py-3 bg-black text-white text-center hover:shadow-lg hover:bg-blue-500 transition ease-out duration-200" href={"/post/" + post.slug.current} key={post.slug.current}>View project</a>
                            </div>


                    </article>
                    ))}
                </div>
            </section>

    )
}