import React from "react";
import { Link } from "react-router-dom";

export default function HeroSection (){
    return       (      
        <>

    <section className="flex flex-col smm:flex-row pt-8 border-b-4 md:border-b-8  border-black md:mx-5 h-90screen">
                <div className="relative justify-center my-20 mx-5 ">
                    <h1 className="h1_hero  pb-5 mb-5 ">FULL STACK <br></br>DESIGNER</h1>

                    <div className="md:w-2/5 bg-white md:p-8 p-5 border-black border-4 rounded-3xl mr-8 flex flex-col">
                        <h2 className="text-3xl pb-4">Bonjour!</h2>
                        <p className="text-xl">I'm William. I am a junior product designer and web developer. I have the needed skills to rapidly ideate, design and deploy digital products. 
                        </p>
                        <Link to="/about"> 
                            <button className="py-2.5 px-9  mt-6 bg-black text-white mb-5 hover:shadow-lg hover:bg-blue-500">Learn more about me</button>
                        </Link>
                    </div>
                </div>

    </section>

    </>
    )
}