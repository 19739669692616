import React from "react";


export default function Page(props) {
    const children = props.children;
    return (
        <main className="min-h-screen">
            {children}
        </main>
    )
}