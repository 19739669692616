import React from "react";
import Page from "../Page";
import Experience from "../Experience"
import wgabout_mb from '../../assets/images/wgabout_mb.png'; 



export default function AboutPage () {
    return (
        
    <>

        <section className="flex flex-col md:flex-row border-b-8 border-black mt-9 pt-5 h-90screen mx-5">
            <div className="relative justify-center my-20 mx-5 ">
            <h1 className="h1_hero  pb-5 mb-5">WILLIAM <br></br>GAUVIN</h1>
            </div>
            <div className="flex my-auto mx-auto">
                
            <img src={wgabout_mb} alt="hello" className="bg-cover"/>

            </div>
        </section>


        <section>

            <div className="container px-5 md:w-2/5 mx-auto py-32 ">
                <h2 className="text-3xl pb-4">Bonjour!</h2>
                <p className="text-xl">I'm currently looking for a job in Design or Developpement. My ideal job would be joining a multidisciplinary team where I can put my large skillset to good use.

                </p>
            </div>


        </section>
    
        
        <Page>
            <section className="mt-8  mx-5 lg:container mx-auto">
                <h2 className="h1 text-5xl mb-5">Work Experience</h2>
                <Experience category={"job"}/>
            </section>

            <section className="mt-8  mx-5 lg:container mx-auto">
                <h2 className="h1 text-5xl mb-5">Diplomas</h2>
                <Experience category={"education"}/>
            </section>

            <section className="mt-8  mx-5 lg:container mx-auto mb-36">
                <h2 className="h1 text-5xl mb-5">Formations</h2>
                <Experience category={"formation"}/>
            </section>
        </Page>
    </>    
    )
};
