import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
    return ( 
        <footer className="mx-5">
            <p className="text-2xl pt-20 my-10 border-t-8  border-black h2">Have a question or a project?</p>
            <p className="pb-20 my-10 border-b-8 border-black h1 h1__lg">
                LET'S GET IN TOUCH!
            </p>
            <div className="flex justify-between items-baseline">
                <div>
                    <SocialIcon url="https://www.linkedin.com/in/william-gauvin-626357105" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{ height: 32, width: 32 }}/>
                    <SocialIcon url="https://dribbble.com/WGOVE" className="mr-4" target="_blank" fgColor="#fff" bgColor="#000" style={{ height: 32, width: 32 }}/>
                </div>
                    <NavLink to="/contact" className="px-6 py-2 bg-black text-white mb-5 hover:shadow-lg hover:bg-blue-500 transition ease-out duration-200 oval">Contact</NavLink>
                <div>
                    <SocialIcon url="https://github.com/John-e-fuel/" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{ height: 32, width: 32 }}/>
                    <SocialIcon url="mailto:will.gauvin@gmail.com" className="mr-4" target="_blank" fgColor="#fff" bgColor="#000" style={{ height: 32, width: 32 }}/>
                </div>

            </div>

        </footer>
     );
}
 
export default Footer;